import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Preview = makeShortcode("Preview");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "starting-with-our-vue-cli-generated-app-lets-install-carbon-and-begin-using-carbon-components-by-the-end-you-will-have-a-vue-app-that-uses-the-ui-shell-to-navigate-between-pages"
    }}>{`Starting with our Vue CLI generated app, let’s install Carbon and begin using Carbon components. By the end you will have a Vue app that uses the UI Shell to navigate between pages.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build and start</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install Carbon</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Other dependencies</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add UI Shell</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create pages</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add routing</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://vue-step-2--carbon-tutorial-vue.netlify.com"
      }}>{`preview`}</a>{` of what you will build:`}</p>
    <Preview height="200" title="Carbon Tutorial Step 1" src="https://vue-step-2--carbon-tutorial-vue.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" mdxType="Preview" />
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` that we’ll use as a starting point for each step.`}</p>
    <h3 {...{
      "id": "fork"
    }}>{`Fork`}</h3>
    <p>{`To begin, fork `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` using your GitHub account.`}</p>
    <h3 {...{
      "id": "clone"
    }}>{`Clone`}</h3>
    <p>{`Go to your forked repository, copy the SSH or HTTPS URL and in your terminal run the two commands to get the repository in your local file system and enter that directory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git clone [your fork SSH/HTTPS]
$ cd carbon-tutorial-vue
`}</code></pre>
    <h3 {...{
      "id": "add-upstream-remote"
    }}>{`Add upstream remote`}</h3>
    <p>{`Add a remote called `}<inlineCode parentName="p">{`upstream`}</inlineCode>{` so we can eventually submit a pull request once you have completed this tutorial step.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote add upstream git@github.com:carbon-design-system/carbon-tutorial-vue.git
`}</code></pre>
    <p>{`Or, if you prefer to use HTTPS instead of SSH with your remotes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote add upstream https://github.com/carbon-design-system/carbon-tutorial-vue.git
`}</code></pre>
    <p>{`Verify that your forked repository remotes are correct:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote -v
`}</code></pre>
    <p>{`Your terminal should output something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`origin  [your forked repo] (fetch)
origin  [your forked repo] (push)
upstream    git@github.com:carbon-design-system/carbon-tutorial-vue.git (fetch)
upstream    git@github.com:carbon-design-system/carbon-tutorial-vue.git (push)
`}</code></pre>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`Now that we have our repository set up, let’s check out the branch for this tutorial step’s starting point. Run the two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b vue-step-1 upstream/vue-step-1
`}</code></pre>
    <h2 {...{
      "id": "build-and-start"
    }}>{`Build and start`}</h2>
    <p>{`We have the repository forked to your GitHub account, cloned down to your machine, and the starting branch checked out. Next, install the Vue app’s dependencies with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`After the dependencies are installed, you can start the app with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`Your default browser should open up with an empty page that says: `}<inlineCode parentName="p">{`Hello Carbon! Well, not quite yet. This is the starting point for the Carbon tutorial.`}</inlineCode></p>
    <p>{`OK. So we made a small change to the Vue CLI geneated app replacing the HelloWorld component and replaced it with our own message and swapped out the facicon.`}</p>
    <h2 {...{
      "id": "install-carbon"
    }}>{`Install Carbon`}</h2>
    <p>{`Even though we installed existing dependencies, we’ve yet to install the Carbon packages.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`carbon-components`}</inlineCode>{` - component styles`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@carbon/vue`}</inlineCode>{` - Vue components`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@carbon/icons-vue`}</inlineCode>{` - Vue icons`}</li>
    </ul>
    <p>{`Stop your development server with `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{` and install Carbon dependencies with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add carbon-components @carbon/vue @carbon/icons-vue
`}</code></pre>
    <h2 {...{
      "id": "other-dependencies"
    }}>{`Other dependencies`}</h2>
    <p>{`If you check out the file `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, you’ll notice a few dependencies beyond those listed above. These were installed as part of the project creation using the Vue CLI. These include:`}</p>
    <ul>
      <li parentName="ul">{`vue-router: Used to make routing in Vue apps simpler`}</li>
      <li parentName="ul">{`@vue/cli-plugin-babel: To ensure we produce well supported code.`}</li>
      <li parentName="ul">{`@vue/cli-plugin-eslint: To allow us to catch potential errors.`}</li>
      <li parentName="ul">{`@vue/cli-plugin-unit-jest: To allow us to unit test our code.`}</li>
      <li parentName="ul">{`node-sass: To allow us to use the sass css precompiler.`}</li>
    </ul>
    <p>{`NOTE: We could have installed these seperately but using the CLI to set this up for us ensures a good base config for these dependencies.`}</p>
    <p>{`To avoid having to add the `}<inlineCode parentName="p">{`~`}</inlineCode>{` prefix when importing SCSS files from `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`, create a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file at the project root that contains:`}</p>
    <h5 {...{
      "id": "env"
    }}>{`.env`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`SASS_PATH="node_modules"
`}</code></pre>
    <p>{`For the Windows operating system, use:`}</p>
    <h5 {...{
      "id": "env-1"
    }}>{`.env`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`SASS_PATH=./node_modules
`}</code></pre>
    <p>{`Then, start the app again. If your app’s currently running, you’ll need to restart it for the new environment variable to be used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`The app looks as it did before. Next, let’s add Carbon styling.`}</p>
    <h3 {...{
      "id": "import-carbon-component-styles"
    }}>{`Import carbon-component styles`}</h3>
    <p>{`In the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory, create a sub-directory `}<inlineCode parentName="p">{`styles`}</inlineCode>{` and add a new file `}<inlineCode parentName="p">{`_carbon.scss`}</inlineCode>{` to it. Then in `}<inlineCode parentName="p">{`App.vue`}</inlineCode>{` edit the style tag to import it.`}</p>
    <h4 {...{
      "id": "srcappvue"
    }}>{`src/App.vue`}</h4>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`<style lang="scss">
@import "./styles/carbon";
</style>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`In `}<inlineCode parentName="p">{`styles/_carbon.scss`}</inlineCode>{`, import the Carbon styles by adding the following at the top of the file:`}</p>
    <h5 {...{
      "id": "srcstyles_carbonscss"
    }}>{`src/styles/`}{`_`}{`carbon.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import 'carbon-components/scss/globals/scss/styles';
`}</code></pre>
    <p>{`This will take a moment for the Sass to compile. Once compiled, you’ll notice that the Carbon base styling is applied (IBM Plex Sans font family, font size, weight, colors, etc.)`}</p>
    <p>{`Because any change to `}<inlineCode parentName="p">{`_carbon.scss`}</inlineCode>{` will re-compile all of the Carbon Sass, avoid making changes here unless instructed to do so. it is better to make them in the component files or a seperate file if needed.`}</p>
    <p>{`Next we’ll create a Carbon `}<inlineCode parentName="p">{`Button`}</inlineCode>{` to test that our dependencies are working properly.`}</p>
    <h4 {...{
      "id": "srcmainjs"
    }}>{`src/main.js`}</h4>
    <p>{`After the other imports in main.js and before the Vue instance is created add the following.`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CarbonComponentsVue from "@carbon/vue";
Vue.use(CarbonComponentsVue);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`This is a quick way to pull in all @carbon/vue components and register them for use in your project. Individual components can be imported to a project or component.`}</p>
    <p>{`e.g. Instead of modifying src/main.js we could have added the following to src/App.vue:`}</p>
    <h5 {...{
      "id": "srcappvue-1"
    }}>{`src/App.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
import { CvButton } from '@carbon/vue';

export default {
  components: {
    CvButton,
  }
};
</script>
`}</code></pre>
    <p>{`See the Carbon Vue Components `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components-vue/blob/master/packages/core/README.md#using-the-components-directly-or-individually"
      }}>{`documentation`}</a>{` for other ways to load components from @carbon/vue components.`}</p>
    <p>{`In this tutorial we will stick to importing all of the components at once so we can focus on our use of @carbon/vue.`}</p>
    <p>{`Now open the `}<inlineCode parentName="p">{`App.vue`}</inlineCode>{` component and replace:`}</p>
    <h5 {...{
      "id": "srcappvue-2"
    }}>{`src/App.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  Hello Carbon! Well, not quite yet. This is the starting point for the Carbon tutorial.
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`with:`}</p>
    <h5 {...{
      "id": "srcappvue-3"
    }}>{`src/App.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<CvButton>Button</CvButton>
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-button>Button</cv-button>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Congratulations, you’ve imported your first component! You should see a Carbon styled button on the page.`}</p>
    <p>{`NOTE: In this tutorial you can use either tag format. The `}<a parentName="p" {...{
        "href": "https://vuejs.org/v2/style-guide/"
      }}>{`Vue style guide`}</a>{` recommend sticking to either Pascal or kebab case. The examples from here will use Pascal case for file and component names with kebab case in the HTML.`}</p>
    <h2 {...{
      "id": "add-ui-shell"
    }}>{`Add UI Shell`}</h2>
    <p>{`Next we’re going to create a Vue component called `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` to use with the UI Shell Carbon component. Create a new directory `}<inlineCode parentName="p">{`src/components`}</inlineCode>{`. In the `}<inlineCode parentName="p">{`src/components`}</inlineCode>{` directory, create `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` directory. Create the following files inside `}<inlineCode parentName="p">{`src/components/TutorialHeader`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/components/TutorialHeader
├──index.js
└──TutorialHeader.vue
`}</code></pre>
    <h3 {...{
      "id": "import-and-export-the-header"
    }}>{`Import and export the header`}</h3>
    <p>{`In `}<inlineCode parentName="p">{`src/components/TutorialHeader/index.js`}</inlineCode>{`, import and export our `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` component like so:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheaderindexjs"
    }}>{`src/components/TutorialHeader/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import TutorialHeader from './TutorialHeader';
export default TutorialHeader;
`}</code></pre>
    <p><em parentName="p">{`Note: This index.js files import/export is simply a convenience to shorten the path used to import the component and potentially import multiple components from one folder. The folder also provides us a handy location to add tests or documentation for the component.`}</em></p>
    <p><em parentName="p">{`Note: We could have simply created a file src/components/TutorialHeader.vue without the above benefits.`}</em></p>
    <h4 {...{
      "id": "lazyness---vscode-users-only"
    }}>{`Lazyness - VSCode users only`}</h4>
    <p>{`If you are using VSCode then you might want to add the following snippet which will when you type ‘index’ generate an index file for you based on the folder name.`}</p>
    <h5 {...{
      "id": "javascriptjson"
    }}>{`javascript.json`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`  "index-file": {
    "prefix": "index",
    "body": [
      "import \${TM_DIRECTORY/.*[\\\\/]//gm} from './\${TM_DIRECTORY/.*[\\\\/]//gm}';",
      "export { ",
      "\\t\${TM_DIRECTORY/.*[\\\\/]//gm},",
      "};",
      "export default \${TM_DIRECTORY/.*[\\\\/]//gm}",
      ""
    ],
    "description": "Index file"
  }
`}</code></pre>
    <p>{`You can also use the following to create a skeleton component. To use this one, start typing the word template in your template file and it will generate a file, making assumptions based on the component file name.`}</p>
    <h5 {...{
      "id": "vuejson"
    }}>{`vue.json`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`  "Vue_Component": {
    "prefix": "template",
    "body": [
      "<template>",
      "\\t$0",
      "</template>",
      "",
      "<script>",
      "export default {",
      "\\tname: '\${TM_FILENAME/[\\\\.]vue//}'",
      "};",
      "</script>",
      "",
      "<style lang=\\"scss\\">",
      "</style>",
      ""
    ],
    "description": "Single file template"
  }
`}</code></pre>
    <p>{`OK, back to using Carbon components. Let’s make use of our Carbon UI Shell components in `}<inlineCode parentName="p">{`TutorialHeader.vue`}</inlineCode>{`. Set up the file like so:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheadervue"
    }}>{`src/components/TutorialHeader/TutorialHeader.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <cv-header aria-label="Carbon tutorial">
    <cv-skip-to-content href="#main-content"
      >Skip to content</cv-skip-to-content
    >

    <cv-header-name href="/" prefix="IBM">Carbon Tutorial</cv-header-name>

    <cv-header-nav>
      <cv-header-menu-item href="/repos">Repositories</cv-header-menu-item>
    </cv-header-nav>

    <template slot="header-global" />
  </cv-header>
</template>
`}</code></pre>
    <p><em parentName="p">{`Note: you can find a description of the different components used in UI Shell in our `}<a parentName="em" {...{
          "href": "http://vue.carbondesignsystem.com/?path=/story/components-cvuishell-header"
        }}>{`carbon-componets-vue`}</a>{` package.`}</em></p>
    <p><em parentName="p">{`Note: When creating navigation headers, it’s important to have a`}</em>{` `}<inlineCode parentName="p">{`Skip to content`}</inlineCode>{` `}<em parentName="p">{`link so keyboard users can skip the navigation items and go straight to the main content.`}</em></p>
    <p><em parentName="p">{`Note: It’s important that the`}</em>{` `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` has the Carbon`}<em parentName="p">{` `}<inlineCode parentName="em">{`CvHeader`}</inlineCode>{` `}{`_`}{`as it’s containing element, as we’ll later be rendering`}</em>{` `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` `}<em parentName="p">{`in`}</em>{` `}<inlineCode parentName="p">{`App.vue`}</inlineCode>{` `}<em parentName="p">{`as a preceeding sibling of`}</em>{` `}<inlineCode parentName="p">{`Content`}</inlineCode><em parentName="p">{`, another UI Shell component. Those components need to live one after another for the UI Shell to properly render.`}</em></p>
    <h3 {...{
      "id": "import-icons"
    }}>{`Import icons`}</h3>
    <p>{`Now let’s import the icons from our `}<inlineCode parentName="p">{`@carbon/icons-vue`}</inlineCode>{` elements package. After the closing template tag in the `}<inlineCode parentName="p">{`TutorialHeader.vue`}</inlineCode>{` file, we need to import each individual icon we will use.`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheadervue-1"
    }}>{`src/components/TutorialHeader/TutorialHeader.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
import Notification20 from "@carbon/icons-vue/es/notification/20";
import UserAvatar20 from "@carbon/icons-vue/es/user--avatar/20";
import AppSwitcher20 from "@carbon/icons-vue/es/app-switcher/20";

export default {
  name: "TutorialHeader",
  components: { Notification20, UserAvatar20, AppSwitcher20 }
};
</script>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p><em parentName="p">{`Note: We’ve given our component a name here as part of the default export. This is optional in Vue but very useful in the `}<a parentName="em" {...{
          "href": "https://github.com/vuejs/vue-devtools"
        }}>{`Vue developer tools`}</a>{`.`}</em></p>
    <p>{`Then we need to add content to the `}<inlineCode parentName="p">{`header-global`}</inlineCode>{` slot where we will use our icons. These represent actions in the header a user can make. Replace:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheadervue-2"
    }}>{`src/components/TutorialHeader/TutorialHeader.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template slot="header-global" />
`}</code></pre>
    <p>{`With:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheadervue-3"
    }}>{`src/components/TutorialHeader/TutorialHeader.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template slot="header-global">
  <cv-header-global-action aria-label="Notifications">
    <notification-20 />
  </cv-header-global-action>
  <cv-header-global-action aria-label="User avatar">
    <user-avatar-20 />
  </cv-header-global-action>
  <cv-header-global-action aria-label="App switcher">
    <app-switcher-20 />
  </cv-header-global-action>
</template>
`}</code></pre>
    <h3 {...{
      "id": "render-the-header"
    }}>{`Render the header`}</h3>
    <p>{`Next we’ll render our UI Shell by importing our `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` component and `}<inlineCode parentName="p">{`CvContent`}</inlineCode>{` into `}<inlineCode parentName="p">{`App.vue`}</inlineCode>{`. Add a script tag if you don’t have one, then update it as follows:`}</p>
    <h5 {...{
      "id": "srcappvue-4"
    }}>{`src/App.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
import TutorialHeader from "./components/TutorialHeader";

export default {
  name: "App",
  components: {
    TutorialHeader
  }
};
</script>
`}</code></pre>
    <p>{`In addition to importing the `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` component, we have also declared it for use in our template by adding it to the `}<inlineCode parentName="p">{`components`}</inlineCode>{` property of our component.`}</p>
    <p>{`As our template currently just contains a `}<inlineCode parentName="p">{`Button`}</inlineCode>{` it is still not rendering anything more interesting so let’s update that to include our imported components. This should look like the following:`}</p>
    <h5 {...{
      "id": "srcappvue-5"
    }}>{`src/App.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div id="app">
    <tutorial-header />
    <cv-content id="#main-content">
      <cv-button>Button</cv-button>
    </cv-content>
  </div>
</template>
`}</code></pre>
    <p>{`You should now see a styled UI Shell header and a button below it.`}</p>
    <p><em parentName="p">{`Note: We’ve also sneaked in use of `}<inlineCode parentName="em">{`CvContent`}</inlineCode>{` which needs to follow `}<inlineCode parentName="em">{`CvHeader`}</inlineCode>{` to ensure the correct formatting. We could have included it as part of a shell component with TutorialHeader but have chosen not to in this case.`}</em></p>
    <h2 {...{
      "id": "create-pages"
    }}>{`Create pages`}</h2>
    <p>{`Next thing we need to do is create the files for our views.`}</p>
    <p>{`Since our app will have two pages, we’ll create two folders in `}<inlineCode parentName="p">{`src/views`}</inlineCode>{`. Clear out the files currently in the views folder and add the following folders.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/views
├── LandingPage
└── RepoPage
`}</code></pre>
    <p>{`Create the following files in the `}<inlineCode parentName="p">{`LandingPage`}</inlineCode>{` folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/view/LandingPage
├── index.js
└── LandingPage.vue
`}</code></pre>
    <p>{`Create the following files in the `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/view/RepoPage
├── index.js
└── RepoPage.vue
`}</code></pre>
    <h3 {...{
      "id": "import-and-export-content-pages"
    }}>{`Import and export content pages`}</h3>
    <p>{`Starting with `}<inlineCode parentName="p">{`LandingPage`}</inlineCode>{`, just like with our header, we need to export the component in `}<inlineCode parentName="p">{`src/view/LandingPage/index.js`}</inlineCode>{` by adding:`}</p>
    <h5 {...{
      "id": "srcviewlandingpageindexjs"
    }}>{`src/view/LandingPage/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import LandingPage from './LandingPage';
export default LandingPage;
`}</code></pre>
    <p>{`Next in `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{` we’ll create our component.`}</p>
    <h5 {...{
      "id": "srcviewlandingpagelandingpagevue"
    }}>{`src/view/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div>LANDING PAGE</div>
</template>
`}</code></pre>
    <p>{`We’ll repeat this process with `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{`.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`src/view/RepoPage/index.js`}</inlineCode>{`, import and export the `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component like so:`}</p>
    <h5 {...{
      "id": "srcviewrepopageindexjs"
    }}>{`src/view/RepoPage/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import RepoPage from './RepoPage';
export default RepoPage;
`}</code></pre>
    <p>{`Then in `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` create the component.`}</p>
    <h5 {...{
      "id": "srcviewrepopagerepopagevue"
    }}>{`src/view/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div>REPO PAGE</div>
</template>
`}</code></pre>
    <p>{`Awesome! We’ve just created our content pages. Next thing we need to do is render them with our router.`}</p>
    <h2 {...{
      "id": "add-routing"
    }}>{`Add routing`}</h2>
    <p>{`Lucky for you, as part of the Vue CLI project set up we added vue-router. This created the views folder and also added, `}<inlineCode parentName="p">{`src/router.js`}</inlineCode>{` and imported that into `}<inlineCode parentName="p">{`src/main.js`}</inlineCode>{` for us.`}</p>
    <h4 {...{
      "id": "srcrouterjs"
    }}>{`src/router.js`}</h4>
    <p>{`We need to adjust it by replacing the contents of `}<inlineCode parentName="p">{`src/router.js`}</inlineCode>{` with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Vue from 'vue';
import Router from 'vue-router';
import LandingPage from './views/LandingPage';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'landing-page',
      component: LandingPage,
    },
    {
      path: '/repos',
      name: 'repo-page',
      // route level code-splitting
      // this generates a separate chunk (repo-page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "repo-page" */ './views/RepoPage'),
    },
  ],
});
`}</code></pre>
    <p><em parentName="p">{`Note: The landing page is loaded immediately, with the RepoPage loaded only once needed. This is one simple way to create webpackChunks and is the default suggestion from the CLI plugin for vue-router.`}</em></p>
    <p>{`If you click on `}<inlineCode parentName="p">{`Repositories`}</inlineCode>{` and then on `}<inlineCode parentName="p">{`IBM Carbon Tutorial`}</inlineCode>{` you’ll notice a flicker as we hop from one page to another. We can fix this by using vue-router to manage our views.`}</p>
    <p>{`Next we need to update `}<inlineCode parentName="p">{`src/App.vue`}</inlineCode>{` to render these views.`}</p>
    <p>{`In the template section remove the `}<inlineCode parentName="p">{`<cv-button />`}</inlineCode>{` and replace it with `}<inlineCode parentName="p">{`<router-vue />`}</inlineCode>{` as follows`}</p>
    <h4 {...{
      "id": "srcappvue-6"
    }}>{`src/App.vue`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-content id="#main-content">
  <router-view />
</cv-content>
`}</code></pre>
    <p>{`After that we need to do a couple quick fixes to the UI Shell to have it work with the vue-router.`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheadervue-4"
    }}>{`src/components/TutorialHeader/TutorialHeader.vue`}</h5>
    <p>{`In `}<inlineCode parentName="p">{`src/components/TuturialHeader/TutorialHeader.vue`}</inlineCode>{` simply replace the `}<inlineCode parentName="p">{`href`}</inlineCode>{` attributes used in the `}<inlineCode parentName="p">{`cv-header-name`}</inlineCode>{` and `}<inlineCode parentName="p">{`cv-header-menu-item`}</inlineCode>{` components with `}<inlineCode parentName="p">{`to`}</inlineCode>{`. @carbon/vue will under the covers switch from use of an `}<inlineCode parentName="p">{`a`}</inlineCode>{` tag to `}<inlineCode parentName="p">{`router-link`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-header-name to="/" prefix="IBM">Carbon Tutorial</cv-header-name>
`}</code></pre>
    <p>{`and`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-header-menu-item to="/repos">Repositories</cv-header-menu-item>
`}</code></pre>
    <p>{`You should now have a working header that routes to different pages without full page reload!`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step and demonstrate a couple of related concepts.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`We have a `}<inlineCode parentName="p">{`ci-check`}</inlineCode>{` script defined in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` that verifies file formatting for files that have been touched since the last Git commit with a tool called `}<a parentName="p" {...{
        "href": "https://prettier.io"
      }}>{`Prettier`}</a>{`. You’d typically also have that script run your test suite as part of your CI build. Go ahead and make sure everything looks good with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: If the`}</em>{` `}<inlineCode parentName="p">{`ci-check`}</inlineCode>{` `}<em parentName="p">{`is giving an error, it’s likely that some of your source files are not properly formatted. This could happen if your text editor isn’t formatting with Prettier on save.`}</em></p>
    {
      /* The following section is outcommented because it is not working: the format
          script is missing from package.json */
    }
    {
      /* To get_ `ci-check` _to pass, run_ `yarn format`_ then re-run_ `yarn ci-check`_._ */
    }
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 1"
`}</code></pre>
    {
      /* Stephane: I can't see any files changed or commited from me in that directory,
          so I wonder if we should mention it. */
    }
    {
      /* _Note: You'll notice that your commit includes binaries in the _`.yarn-offline-mirror`_ folder. That's expected as the repository is configured to run [Yarn offline](https://yarnpkg.com/blog/2016/11/24/offline-mirror) for more reliable builds. Future tutorial steps that don't install new packages won't have _`.yarn-offline-mirror`_ commit changes._ */
    }
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin vue-step-1
`}</code></pre>
    <p><em parentName="p">{`Note: If your Git remote protocol is HTTPS instead of SSH, you may be prompted to authenticate with GitHub when you push changes. If your GitHub account has two-factor authentication enabled, we recommend that you follow these instructions to `}<a parentName="em" {...{
          "href": "https://help.github.com/en/articles/creating-a-personal-access-token-for-the-command-line"
        }}>{`create a personal access token for the command line`}</a>{`. That lets you use your token instead of password when performing Git operations over HTTPS.`}</em></p>
    <p><em parentName="p">{`Note: If you receive a`}</em>{` `}<inlineCode parentName="p">{`non-fast-forward`}</inlineCode>{` `}<em parentName="p">{`error, it’s likely that your forked repository is behind the original repository and needs updated. This can happen if the tutorial was updated after you began working on it. To fix, run`}</em>{` `}<inlineCode parentName="p">{`git pull upstream vue-step-1`}</inlineCode>{` `}<em parentName="p">{`to merge the changes into your branch, then you can try pushing again. Or, you can `}<a parentName="em" {...{
          "href": "https://help.github.com/en/articles/syncing-a-fork"
        }}>{`manually merge`}</a>{` in the upstream changes.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` to “Compare & create pull request”. In doing so, make sure that you are comparing your repository’s `}<inlineCode parentName="p">{`vue-step-1`}</inlineCode>{` branch into `}<inlineCode parentName="p">{`base: vue-step-1`}</inlineCode>{`. Take notice of the `}<a parentName="p" {...{
        "href": "https://www.netlify.com"
      }}>{`Netlify`}</a>{` bot that deploys a preview of your PR every time that you push new commits. These previews can be shared and viewed by anybody to assist the PR review process.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      